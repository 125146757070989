<template>
  <div>
    <center-left1 :urlKey="urlKey"></center-left1>
    <center-left2 :urlKey="urlKey"></center-left2>
  </div>
</template>
<script>
import centerLeft1 from "./centerLeft1.vue";
import centerLeft2 from "./centerLeft2.vue";
export default {
  name: "centerLeft",
  data() {},
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  components: {
    centerLeft1,
    centerLeft2,
  },
};
</script>
