<template>
  <div class="mapBox">
    <div style="height: 90%; width: 100%" ref="myChart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import request from "@/api/request";
// import "../../../assets/js/china.js";

export default {
  name: "center",
  data() {
    return {
      dataList: [],
    };
  },
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    urlKey: {
      handler() {
        this.getData();
      },
    },
  },
  methods: {
    async getData() {
      const res = await request.post("/anno/largeScreen/getLargeScreen5", {
        urlKey: this.urlKey,
      });
      this.dataList = res.data.data;
      this.initEchartMap();
    },
    initEchartMap() {
      let myChart = echarts.init(this.$refs.myChart);
      let options = {
        title: {
          text: "结算热力图",
          // subtext: "朝阳",
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 30,
          },
          subtextStyle: {
            fontSize: 20,
          },
        },
        tooltip: {
          triggerOn: "click",
          formatter: function (e) {
            return e.seriesName + "<br />" + e.name + "：" + e.value;
          },
        },
        // 热力地图
        visualMap: {
          min: 0,
          max: 1000,
          left: 10,
          textStyle: {
            color: "#fff",
          },
          pieces: [
            {
              gte: 100000000,
              label: "一亿以上",
              color: "#F83802",
            },
            {
              gte: 50000000,
              lt: 100000000,
              label: "≥5000万~1亿",
              color: "#F98902",
            },
            {
              gte: 10000000,
              lt: 50000000,
              label: "≥1000~5000万",
              color: "#E1FF11",
            },
            {
              gte: 1000000,
              lt: 10000000,
              label: "≥100~1000万",
              color: "#A7FF35",
            },
            {
              gt: 0,
              lt: 1000000,
              label: "≥1~100万",
              color: "#6DFF57",
            },
          ],
        },

        series: [
          {
            name: "结算额",
            data: this.dataList,
            type: "map",
            map: "china",
            zoom: 1.2,
            aspectScale: 0.75,
            label: {
              // 默认文本标签样式
              normal: {
                color: "white",
                show: true,
              },
              // 高亮文本标签样式
              emphasis: {
                color: "yellow",
                fontSize: 22,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              // 默认区域样式
              normal: {
                // 区域背景透明
                areaColor: "transparent",
                borderColor: "rgba(39,211,233, 1)",
                borderWidth: 1,
              },
              // 高亮区域样式
              emphasis: {
                // 高亮区域背景色
                areaColor: "#01ADF2",
              },
            },
          },
        ],
      };

      myChart.setOption(options);

      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     this.getData();
  //     // this.initEchartMap();
  //   });
  // },
};
</script>
<style scoped>
.mapBox {
  background: #080a20;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-boxs;
}
</style>
