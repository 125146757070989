var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"viewport1"},[_c('div',{staticClass:"column"},[_c('h3',[_vm._v("运营数据")]),_c('div',{staticClass:"overview panel"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s((_vm.data.platOrderAmtTotal / 10000).toFixed(4)))]),_vm._m(0)]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s((_vm.data.invoiceAmtTotal / 10000).toFixed(4)))]),_vm._m(1)])])])]),_c('div',{staticClass:"column"},[_c('h3',[_vm._v(" ")]),_c('div',{staticClass:"overview panel"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s(_vm.data.yesterdaySohoTotal))]),_vm._m(2)]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s(_vm.data.yesterdayMerTotal))]),_vm._m(3)]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s(_vm.data.yesterdayAgentTotal))]),_vm._m(4)]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s(_vm.data.yesterdayTaskTotal))]),_vm._m(5)])])])]),_c('div',{staticClass:"column"},[_c('h3',[_vm._v("合作人才数据")]),_c('div',{staticClass:"overview panel"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s((_vm.data.sohoTotal / 10000).toFixed(4)))]),_vm._m(6)]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v(_vm._s((_vm.data.monthSohoTotal / 10000).toFixed(4)))]),_vm._m(7)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#006cff"}}),_vm._v(" 结算金额【万元】 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#6acca3"}}),_vm._v(" 开票金额【万元】 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#006cff"}}),_vm._v(" 昨日新增人才 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#6acca3"}}),_vm._v(" 昨日新增企业 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#6acca3"}}),_vm._v(" 昨日新增推广 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#ed3f35"}}),_vm._v(" 昨日新增任务 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#006cff"}}),_vm._v(" 人才总量【万人】 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-dot",staticStyle:{"color":"#6acca3"}}),_vm._v(" 本月新增【万人】 ")])
}]

export { render, staticRenderFns }