<template>
  <div class="monitor panel">
    <div class="inner">
      <div class="content" style="display: block">
        <h3>结算数据</h3>
        <div class="head">
          <span class="col">用工企业</span>
          <span class="col">结算金额</span>
          <span class="col">结算时间</span>
        </div>
        <div class="marquee-view">
          <div class="marquee">
            <div class="row" :key="index" v-for="(item, index) in this.data">
              <span class="col">{{ item.merName }}</span>
              <span class="col">{{ item.amt }}</span>
              <span class="col">{{ item.orderEndTime }}</span>
              <span class="icon-dot"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/api/request.js";
export default {
  data() {
    return {
      data: [],
    };
  },
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    urlKey: {
      handler() {
        this.getData();
      },
    },
  },
  methods: {
    async getData() {
      const res = await request.post("/anno/largeScreen/getLargeScreen2", {
        urlKey: this.urlKey,
      });
      this.data = res.data.data;
      // console.log(this.config);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/index5.scss";
@import "../../../assets/scss1/fonts/icomoon.css";
</style>
