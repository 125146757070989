<template>
  <div>
    <center-right1 :urlKey="urlKey"></center-right1>
    <center-right2 :urlKey="urlKey"></center-right2>
  </div>
</template>
<script>
import centerRight1 from "./centerRight1.vue";
import centerRight2 from "./centerRight2.vue";
export default {
  name: "centerRight",
  data() {},
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  components: {
    centerRight1,
    centerRight2,
  },
};
</script>
