<template>
  <div class="users panel">
    <div class="inner">
      <h3>项目关键云词</h3>
      <div ref="Chart2" style="height: 100%"></div>
    </div>
  </div>
</template>
<script>
// import request from '@/api/request.js'

import request from "@/api/request";

export default {
  data: function () {
    return {
      myChart: null,
    };
  },
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    urlKey: {
      handler() {
        this.initEchart();
      },
    },
  },
  // mounted() {
  //   this.initEchart();
  // },
  methods: {
    async initEchart() {
      // this.$loading.show();
      this.myChart = this.$echarts.init(this.$refs.Chart2);
      this.myChart.showLoading();
      const res = await request.post("/anno/largeScreen/getLargeScreen3", {
        urlKey: this.urlKey,
      });
      if (res.data.data) {
        let data = res.data.data;
        let list = [];
        for (let i = 0; i < data.length; i++) {
          let projectMccName = data[i]["projectMccName"];
          let value = data[i]["value"];
          list.push({ name: projectMccName, value: value });
        }
        this.myChart.hideLoading();
        this.myChart.setOption({
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          grid: {
            left: '20%',
            // right: '5%',
            // borderWidth: 0,
            top: 5,
            // bottom: 95,
            // textStyle: {
            //   color: '#fff'
            // }
          },
          series: [
            {
              type: "wordCloud",
              gridSize: 2,
              shape: "circle",
              // shape: 'pentagon',
              sizeRange: [12, 50],
              // rotationRange: [-90, 90],
              // width: 600,
              // height: 300,
              left: "center",
              top: "center",
              width: "100%",
              height: "100%",
              right: null,
              bottom: null,
              drawOutOfBound: true,
              textStyle: {
                normal: {
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  color: function () {
                    return (
                      "rgb(" +
                      [
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160),
                      ].join(",") +
                      ")"
                    );
                  },
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowColor: "#333",
                },
              },
              data: list,
            },
          ],
        });
      }
      // this.axios
      //     .post("/anno/largeScreen/getLargeScreen3")
      //     .then(res => {
      //       let data = res.data.data;
      //       let list = [];
      //       for (let i = 0; i < data.length; i++) {
      //         let book_name = data[i]["book_name"];
      //         let comment_nums = data[i]["comment_nums"];
      //         list.push({ name: book_name, value: comment_nums });
      //       }
      //       this.myChart.hideLoading();
      //       this.myChart.setOption({
      //         backgroundColor:'#fff',
      //         tooltip: {
      //           pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      //         },
      //         series: [
      //           {
      //             type: "wordCloud",
      //             gridSize: 2,
      //             shape: "circle",
      //             // shape: 'pentagon',
      //             sizeRange: [12, 50],
      //             // rotationRange: [-90, 90],
      //             // width: 600,
      //             // height: 300,
      //             left: "center",
      //             top: "center",
      //             width: "70%",
      //             height: "80%",
      //             right: null,
      //             bottom: null,
      //             drawOutOfBound: false,
      //             textStyle: {
      //               normal: {
      //                 fontFamily: "sans-serif",
      //                 fontWeight: "bold",
      //                 color: function() {
      //                   return (
      //                       "rgb(" +
      //                       [
      //                         Math.round(Math.random() * 160),
      //                         Math.round(Math.random() * 160),
      //                         Math.round(Math.random() * 160)
      //                       ].join(",") +
      //                       ")"
      //                   );
      //                 }
      //               },
      //               emphasis: {
      //                 shadowBlur: 10,
      //                 shadowColor: "#333"
      //               }
      //             },
      //             data: list
      //           }
      //         ]
      //       });
      //
      //     });
      // this.$loading.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/index5.scss";
@import "../../../assets/scss1/fonts/icomoon.css";
</style>
