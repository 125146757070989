<template>
  <div class="viewport1">
    <div class="column">
      <h3>运营数据</h3>
      <div class="overview panel">
        <div class="inner">
          <div class="item">
            <h4>{{ (data.platOrderAmtTotal / 10000).toFixed(4) }}</h4>
            <span>
              <i class="icon-dot" style="color: #006cff"></i>
              结算金额【万元】
            </span>
          </div>
          <div class="item">
            <h4>{{ (data.invoiceAmtTotal / 10000).toFixed(4) }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              开票金额【万元】
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <h3>&nbsp;</h3>
      <div class="overview panel">
        <div class="inner">
          <div class="item">
            <h4>{{ data.yesterdaySohoTotal }}</h4>
            <span>
              <i class="icon-dot" style="color: #006cff"></i>
              昨日新增人才
            </span>
          </div>
          <div class="item">
            <h4>{{ data.yesterdayMerTotal }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              昨日新增企业
            </span>
          </div>
          <div class="item">
            <h4>{{ data.yesterdayAgentTotal }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              昨日新增推广
            </span>
          </div>
          <div class="item">
            <h4>{{ data.yesterdayTaskTotal }}</h4>
            <span>
              <i class="icon-dot" style="color: #ed3f35"></i>
              昨日新增任务
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <h3>合作人才数据</h3>
      <div class="overview panel">
        <div class="inner">
          <div class="item">
            <h4>{{ (data.sohoTotal / 10000).toFixed(4) }}</h4>
            <span>
              <i class="icon-dot" style="color: #006cff"></i>
              人才总量【万人】
            </span>
          </div>
          <div class="item">
            <h4>{{ (data.monthSohoTotal / 10000).toFixed(4) }}</h4>
            <span>
              <i class="icon-dot" style="color: #6acca3"></i>
              本月新增【万人】
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../../utils/drawMixin";
import request from "@/api/request.js";
export default {
  name: "centerHead",
  mixins: [drawMixin],
  data() {
    return {
      // timing: null,
      // loading: true,
      data: "",
    };
  },
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    urlKey: {
      handler() {
        this.getData();
      },
    },
  },
  // beforeDestroy () {
  //   clearInterval(this.timing)
  // },
  methods: {
    async getData() {
      const res = await request.post("/anno/largeScreen/getLargeScreen", {
        urlKey: this.urlKey,
      });
      this.data = res.data.data;
    },
    // cancelLoading() {
    //   setTimeout(() => {
    //     this.loading = false
    //   }, 500)
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/index5";
@import "../../../assets/scss1/fonts/icomoon.css";
</style>

<style lang="scss" scoped>
#index {
  .viewport1 {
    /* 限定大小 */
    min-width: 1024px;
    // max-width: 1920px;
    min-height: 80px;
    margin: 0 auto;
    //background: url('../../../assets/logo.png') no-repeat 0 0 / contain;
    display: flex;
    padding: 0.667rem 0.833rem 0;
  }
  .column {
    flex: 3;
    position: relative;
  }
  .column:nth-child(2) {
    flex: 4;
    margin: 0 0.833rem 0;
  }
}
</style>
