<template>
  <div class="users panel">
    <div class="inner">
      <h3>近1年充值与结算交易分析</h3>
      <div class="chart">
        <Echart
          :options="options"
          id="centreLeft1Chart"
          height="220px"
        ></Echart>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/api/request";
import Echart from "@/common/echart";
export default {
  name: "centerLeft1",
  data() {
    return {
      data: "",
      cdata: {
        xData: [],
        platOrderAmtTotalMonthList: [],
        rechargeAmtTotalMonthList: [],
      },
      options: {},
    };
  },
  mounted() {
    // this.getData();
  },
  props: {
    urlKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    urlKey: {
      handler() {
        this.getData();
      },
    },
    cdata: {
      handler(newData) {
        this.options = {
          grid: {
          left: '15%',
          top: 15,
        },
          color: [
            "#e7bcf3",
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#8378ea",
          ],
          tooltip: {
            trigger: "item",
            formatter: function (value) {
              let v;
              if (value.value >= 100000000) {
                v = value.value / 100000000 + "亿";
              } else if (value.value >= 10000000) {
                v = value.value / 10000000 + "千万";
              } else if (value.value >= 10000) {
                v = value.value / 10000 + "万";
              } else {
                v = value.value;
              }
              return value.name+': &nbsp;'+ v;
            }
            // formatter: "{a} <br/>{b} : {c}",
          },
          toolbox: {
            show: true,
          },
          legend: {
            show: true, // 是否显示图例
            type: 'plain', // 图例类型，可选值：'plain'（普通图例）或'scroll'（可滚动图例）
            orient: 'horizontal', // 图例的布局朝向，可选值：'horizontal'（水平布局）或'vertical'（垂直布局）
            data: ['结算','充值'], // 图例的数据，根据实际情况进行填充
          },
          xAxis: {
            type: "category",
            data: newData.xData,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: function (value) {
                if (value >= 100000000) {
                  return value / 100000000 + "亿";
                } else if (value >= 10000000) {
                  return value / 10000000 + "千万";
                } else if (value >= 10000) {
                  return value / 10000 + "万";
                } else {
                  return value;
                }
              }
            }
          },
          series: [
            {
              name: '结算',
              data: newData.platOrderAmtTotalMonthList,
              type: "line",
            },
            {
              name: '充值',
              data: newData.rechargeAmtTotalMonthList,
              type: "line",
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    Echart,
  },
  methods: {
    async getData() {
      const res = await request.post("/anno/largeScreen/getLargeScreen1", {
        urlKey: this.urlKey,
      });
      this.cdata = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/index5.scss";
@import "../../../assets/scss1/fonts/icomoon.css";
</style>
