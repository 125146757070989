<template>
  <div id="index"  @wheel.prevent="handleMouseWheel">
    <div id="head1">
      <header class="header">
        <div class="header_center">
          <h2>
            <strong>{{ title }}</strong>
          </h2>
        </div>
        <!--<div class="nav text_right">&nbsp;</div>-->
      </header>
    </div>
    <centerHead :urlKey="urlKey"></centerHead>
    <div class="viewport">
      <div class="column">
        <column1 :urlKey="urlKey"></column1>
      </div>
      <div class="column">
        <column2 :urlKey="urlKey"></column2>
      </div>
      <div class="column">
        <column3 :urlKey="urlKey"></column3>
      </div>
    </div>
  </div>
</template>
<script>
import centerHead from "./centerHead/index.vue";
import column1 from "./centerLeft/index.vue";
import column2 from "./center/index.vue";
import column3 from "./centerRight/index.vue";
import request from "@/api/request";
// import '../../common/map/china.js'
export default {
  name: "index2",
  data() {
    return {
      timing: null,
      loading: true,
      decorationColor: ["#568aea", "#000000"],
      urlKey: null,
      title: "",
    };
  },
  // watch: {
  //   data1: {
  //     handler() {
  //       this.options = {
  //         // 这里编写 ECharts 配置
  //       };
  //     },
  //     // 立即监听
  //     immediate: true,
  //     // 深度监听
  //     deep: true,
  //   },
  // },
  created() {},
  mounted() {
    var queryString = location.search.substring(1);
    var params = this.parseQueryString(queryString);
    this.urlKey = params.urlKey;
    this.name();
  },
  methods: {
    handleMouseWheel(event) {
      // 阻止鼠标滚动事件的默认行为
      event.preventDefault();
    },
    async name() {
      const res = await request.get(
        "/anno/largeScreen/statistics/" + this.urlKey
      );
      this.title = res.data.data;
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    parseQueryString(query) {
      var parts = query.split("&");
      var params = Object.create(null);
      for (var i = 0, ii = parts.length; i < ii; ++i) {
        var param = parts[i].split("=");
        var key = param[0];
        var value = param.length > 1 ? param[1] : null;
        params[decodeURIComponent(key)] = decodeURIComponent(value);
      }
      return params;
    },
  },
  components: {
    centerHead,
    column1,
    column2,
    column3,
  },
};
</script>
<style lang="scss" scoped>
#index {
  .head {
    .dv-dec-10,
    .dv-dec-10-s {
      width: 33.3%;
      height: 12px;
    }
    .dv-dec-10-s {
      transform: rotateY(180deg);
    }
    .dv-dec-8 {
      width: 200px;
      height: 50px;
    }
    .title {
      position: relative;
      width: 500px;
      text-align: center;
      background-size: cover;
      background-repeat: no-repeat;

      .title-text {
        font-size: 24px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        color: #fff;
      }

      .dv-dec-6 {
        position: absolute;
        bottom: -30px;
        left: 50%;
        width: 250px;
        height: 8px;
        transform: translate(-50%);
      }
    }
  }
  .viewport {
    /* 限定大小 */
    min-width: 1024px;
    // max-width: 1920px;
    min-height: 600px;
    margin: 0 auto;
    //background: url('../../assets/logo.png') no-repeat 0 0 / contain;

    display: flex;
    padding: 0 0.833rem 0;
  }
  .column {
    flex: 3;
    position: relative;
  }
  .column:nth-child(2) {
    flex: 4;
    margin: 0 0.833rem 0;
  }
}
#head1 {
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clear {
    clear: both;
  }

  .text_right {
    text-align: right;
  }
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background: url("../../assets/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .header_center {
    //width: 30%;
    margin: 0px auto;
    color: #ffffff;
    text-align: center;
    // height: 60px;
    //background-image: url("../../assets/logoBg.png");
    // background-size: 100% 100%;
    font-family: "微软雅黑" !important;
  }
  .header_center h2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }
  .color_font {
    color: #e8f7fe !important;
    font-size: 12px !important;
  }
  .header_logo {
    margin-left: 1%;
    margin-top: 12px;
  }
  .header_logo img {
    height: 56px;
  }
  .nav {
    width: 35%;
  }
}
</style>
